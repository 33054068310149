<template>
  <div class="footer">
    <p style="font-size: 26px;">京都超市应有尽有</p>
    <div>
      <div>
        <p>Copyringt © 2024  &nbsp; 旧街野猫 &nbsp;本站资源来源于网络</p>
      </div>
      <p>
        <a href="https://beian.miit.gov.cn/" target="=_blank" rel="nofollow">鲁ICP备-2024091410号</a>
        &nbsp;&nbsp;
        <a href="#">XML地图</a>
        &nbsp;&nbsp;
        <a href="https://lyq520.love" target="_blank">旧街野猫</a>
      </p>

    </div>


  </div>

</template>

<script>

</script>

<style scoped>
/* .footer{
	
} */
</style>