<template>
    <div class="main">
      <router-view></router-view>
    </div>
</template>

<script>

</script>

<style scoped>

</style>