<template>
  <div class="main">
    <router-view></router-view>
  </div>
</template>


<style scoped>
.container{
  margin: 10px auto;
  width: 1280px;
  display: flex;
 /* position: absolute; */
 padding: 10px 0 10px 0;
}
</style>