// 全局函数，用于加载并执行JS
const productList = require('@/data/product.json')


const warpList = [
    require('@/assets/swapper/sylbt1.jpg'),
    require('@/assets/swapper/sylbt2.jpg'),
    require('@/assets/swapper/sylbt3.jpg')
]

// 当调用 getProductList() 函数时，它将返回一个名为 productList 的变量的值。
export function getProductList() {
    return productList
}

export function getWarpList() {
    return warpList
}